.dataTables_wrapper
    select,
    .dataTables_filter
        input
            color: #4a5568
            padding:
                left: 1rem
                right: 1rem
                top: .5rem
                bottom: .5rem
            line-height: 1.25
            border:
                width: 2px
                radius: .25rem
                color: #edf2f7
            background-color: #edf2f7

    .dataTables_paginate
        .paginate_button
            font-weight: 700
            border-radius: .25rem
            border: 1px solid transparent

            &.current
                color: #fff !important
                box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06)
                font-weight: 700
                border-radius: .25rem
                background: #667eea !important
                border: 1px solid transparent

            &:hover
                color: #fff !important
                box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06)
                font-weight: 700
                border-radius: .25rem
                background: #667eea !important
                border: 1px solid transparent
table
    &.dataTable
        &.hover,
        &.display
            tbody
                tr
                    &:hover
                        background-color: #ebf4ff

        &.no-footer
            border-bottom: 1px solid #e2e8f0
            margin:
                top: .75em
                bottom: .75em

        &.dtr-inline
            &.collapsed
                &>tbody
                    &>tr
                        &>td,
                        &>th
                            &:first-child:before
                                background-color: #667eea !important

#participants
    td
        &:last-child
            padding: 4px 8px

#gagnants
    td
        padding: 10px 12px

#nprogress
    .bar
        height: 4px !important



#menu-toggle
    width: 24px
    height: 24px
    position: relative
    cursor: pointer
    border-radius: 5px
    #menu-hamburger
        position: absolute
        height: 100%
        width: 100%
        span
            transition: .25s ease-in-out
            width: 24px
            height: 3px
            position: relative
            top: 0px
            left: 0px
            margin: 0.25rem 0
            &:nth-child(1)
                transition-delay: 0.5s
            &:nth-child(2)
                transition-delay: 0.625s
            &:nth-child(3)
                transition-delay: 0.75s
    #menu-cross
        position: absolute
        height: 100%
        width: 100%
        transform: rotate(45deg)
        span
            transition: .25s ease-in-out
            &:nth-child(1)
                height: 0%
                width: 3px
                position: absolute
                top: 0px
                left: 10px
                transition-delay: 0s
            &:nth-child(2)
                width: 0%
                height: 3px
                position: absolute
                left: 0px
                top: 10px
                transition-delay: 0.25s

    span
        display: block
        background: #fff
        border-radius: 2px


#menu-toggle.open
    #menu-hamburger
        span
            width: 0%
            transition: .25s ease-in-out
            &:nth-child(1)
                transition-delay: 0s
            &:nth-child(2)
                transition-delay: 0.125s
            &:nth-child(3)
                transition-delay: 0.25s

    #menu-cross
        span
            transition: .25s ease-in-out
            &:nth-child(1)
                height: 100%
                transition-delay: 0.625s
        span
            transition: .25s ease-in-out
            &:nth-child(2)
                width: 100%
                transition-delay: 0.375s


.ease
    transition: 1000ms ease all

.bgimg
    background-image: none !important


/* #site-wrapper
    position: relative
    overflow: hidden
    width: 100%
    height: 5000px */

/* #site-canvas
    width: 100%
    height: 100%
    position: relative
    transform: translateX(0)
    transform: translate3d(0, 0, 0)
    backface-visibility: hidden
    padding: 1% 0 */

/* .show-nav
    #site-canvas
        transform: translateX(300px)
        transform: translate3d(300px, 0, 0) */

/* #site-menu
  width: 300px
  height: 100%
  position: absolute
  top: 0
  left: -300px
  background: #428bca
  padding: 15px */

.dataTables_length select
    padding: 0.5rem 1.95rem 0.5rem 0.5rem !important
    line-height: 1rem


